import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/Form"

const HometownHeroes = () => {
  return (
    <Layout language="en">
      <SEO
        title="Hometown Heroes"
        description="Nominate a first responder, military personnel, healthcare worker, or other frontline hero for our Hometown Heroes program!"
      />
      <div className="hh-23">
        <div className="hh-23__hero">
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/Hometown_Heroes-Logo"
            alt="hometown heroes logo"
          />
          <h1>
            Do you know a first responder, military personnel, healthcare
            worker, or other frontline hero who goes above and beyond to make
            our community a better place? Nominate them as a Hometown Hero!
          </h1>
        </div>

        <div className="hh-23__image-wall">
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-one"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-two"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-three"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-four"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-five"
            alt="people smiling"
          />
        </div>

        <div className="hh-23__section">
          <div className="hh-23__container">
            <div className="hh-23__content">
              <div>
                <p>
                  Nalchajian Orthodontics knows the importance of first
                  responders and all frontline workers in our community. These
                  essential workers care for us and keep us safe, putting the
                  needs of others above their own. Our Hometown Heroes program
                  wants to help one of these heroes in our community smile a
                  little brighter with a free orthodontic treatment (
                  <Link
                    to="/services/braces-fresno-ca/"
                    title="Learn more about braces">
                    braces
                  </Link>{" "}
                  or{" "}
                  <Link
                    to="/services/invisalign-fresno-clovis-ca/"
                    title="Learn more about Invisalign">
                    Invisalign<sup>Ⓡ</sup>
                  </Link>
                  ).
                </p>

                <p>
                  After reviewing the many applications, we selected Master
                  Sergeant Thomas J. Fabrie. Known as Joe to his friends and
                  family, he is an amazing father, husband, friend, and soldier.
                  Joe has served our country proudly for more than 20 years.
                </p>

                <p>
                  “[Joe] always puts the children's needs and our family before
                  anyone else and even though he could benefit from orthodontic
                  work, he never thought twice about having the boys get theirs
                  first,” Joe’s wife, who nominated him, wrote.
                </p>

                <p>
                  Joe joined the US Air Force directly after high school and was
                  active duty for 6 years. He then became an active reservist
                  for the last 15 years. He saw combat in Iraq and is a part of
                  the 701st Combat Operations Squadron. He is the JACCE
                  Superintendent for the 701st COS, March Air Reserve Base,
                  California. After serving our country for more than 20 years,
                  Joe will retire in July 2023.
                </p>

                <p>
                  <b>AWARDS AND DECORATIONS</b>
                </p>
                <ul>
                  <li>Air and Space Commendation Medal </li>
                  <li>Air and Space Achievement Medal </li>
                  <li></li>
                  <li>Air and Space Outstanding Unit Award</li>
                  <li>Combat Readiness Medal</li>
                  <li>Air Reserve Forces Meritorious Service Medal </li>
                  <li>National Defense Service Medal </li>
                  <li>Global War on Terrorism Expeditionary Medal </li>
                  <li>Global War on Terrorism Service Medal </li>
                  <li>Korean Defense Service Medal </li>
                  <li>
                    Air and Space Expeditionary Service Ribbon with Gold Border
                  </li>
                  <li>Air and Space Longevity Service Award </li>
                  <li>Armed Forces Reserve Medal</li>
                  <li>Small Arms Expert Marksmanship Ribbon (Rifle) </li>
                  <li>Air and Space Training Ribbon</li>
                </ul>

                <p>
                  We look forward to helping Joe straighten his smile as he
                  continues to make a difference and be a true hero in our
                  community.
                </p>

                <p>
                  Stay tuned to our{" "}
                  <a
                    href="https://www.facebook.com/Nalchajian.Orthodontics"
                    title="Follow us on Facebook"
                    target="_blank">
                    Facebook
                  </a>
                  and{" "}
                  <a
                    href="https://www.instagram.com/nalchajianorthodontics/"
                    title="Follow us on Instagram"
                    target="_blank">
                    Instagram
                  </a>{" "}
                  pages for updates on Joe’s journey to a new smile!
                </p>

                <p>
                  <i>
                    Learn more about how{" "}
                    <Link
                      to="/services/braces-fresno-ca/"
                      title="Learn more about braces">
                      braces
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/services/invisalign-fresno-clovis-ca/"
                      title="Learn more about Invisalign">
                      Invisalign
                    </Link>{" "}
                    can benefit your smile or the smile of someone you know.{" "}
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HometownHeroes
